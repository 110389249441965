/* 全局样式重置 */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
    text-decoration: none;
  }
  
  html {
    font-size: 16px;
  }
  
  @font-face {
    font-family: 'LXGWWenKai';
    src: url('../font/LXGWWenKai-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  body {
    font-family: 'LXGWWenKai', sans-serif;
    background: linear-gradient(135deg, #ffffff 0%, #bce8fc 100%);
    color: #333;
    min-width: 320px;
    padding-top: 4rem; /* 根据导航栏的新高度进行调整 */
  }
  #about,
  #education,
  #research,
  #competitions {
    scroll-margin-top: 4rem;
  }
  
  /* 顶部导航栏样式 */
  .navbar {
    background-color: transparent; /* 初始背景透明 */
    padding: 0.5rem 1rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: none; /* 初始状态无阴影 */
    transition: background-color 1s ease, box-shadow 1s ease; /* 添加过渡效果 */
  }
  
  /* 导航容器 */
  .navbar .nav-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .navbar.scrolled {
    background: rgba(205, 237, 255, 0.65); /* 渐变背景 */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* 添加阴影 */
  }
  
  /* Logo 样式 */
  .navbar .logo img {
    height: 3rem;
  }
  
  /* 导航菜单样式 */
  .navbar .nav-menu {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin: 0 auto; /* 居中导航菜单 */
  }
  
  .navbar .nav-menu li {
    margin: 0 1rem;
  }
  
  .navbar .nav-menu li a {
    color: #333;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* 移动端菜单图标样式 */
  .navbar .mobile-menu-icon {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    right: 1rem; /* 将菜单图标定位到右侧 */
  }
  
  /* 悬停时的样式 */
  .navbar .nav-menu li a:hover {
    background-color: rgb(182, 232, 255);
    color: #ffffff;
  }
  
  .navbar .nav-menu li a:active,
  .navbar .nav-menu li a:focus {
    background-color: rgba(0, 0, 0, 0.1);
    color: #000;
    outline: none;
  }
  
  /* 响应式布局 */
  @media (max-width: 768px) {
    /* 显示移动端菜单图标，隐藏导航菜单 */
    .navbar .mobile-menu-icon {
      display: block;
    }
  
    .navbar .nav-menu {
      display: none;
      flex-direction: column;
      background-color: rgba(255, 255, 255, 0.95);
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding: 1rem 0;
    }
  
    .navbar .nav-menu.active {
      display: flex;
    }
  
    .navbar .nav-menu li {
      margin: 0;
    }
  
    .navbar .nav-menu li a {
      padding: 1rem;
      text-align: center;
      display: block;
    }
  }
  
  /* 个人简介部分样式 */
  .intro-section {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .intro-container {
    display: flex;
    justify-content: space-between;
    width: 65%;
    max-width: 75rem;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 12px rgba(127, 208, 255, 0.55);
    margin-bottom: 2rem;
    min-width: 300px;
    flex-wrap: wrap;
  }
  
  /* 轮播图样式 */
  .carousel {
    width: 45%;
    max-width: 25rem;
    /* position: relative; */
    display: flex;
    min-width: 10rem;
  }
  
  .carousel-img {
    width: 100%;
    height: auto;
    aspect-ratio: 4 / 3;
    object-fit: cover;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* 信息栏样式 */
  .info-panel {
    width: 50%;
    max-width: 35rem;
    padding-left: 3rem;
    min-width: 15rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
  }
  
  .chinese-name {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  
  .school, .email, .phone {
    font-size: 1.125rem;
    margin-top: 0.5rem;
  }
  
  /* 文字介绍部分样式 */
  .bio {
    margin-top: 3rem;
    width: 75%;
    max-width: 75rem;
    text-align: left;
  }
  
  .bio h3 {
    font-size: 1.625rem;
    margin-bottom: 0.625rem;
    color: #333;
  }
  
  .bio p {
    font-size: 1.125rem;
    line-height: 1.6;
    color: #555;
  }
  
  /* 学习经历部分样式 */
  .education-section {
    padding: 2.5rem;
    background-color: rgba(255, 255, 255, 0.9);
    width: 75%;
    max-width: 75rem;
    margin: 0 auto;
  }
  
  .education-section h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .education-section p {
    font-size: 1.125rem;
    line-height: 1.6;
    color: #555;
  }
  
  /* 科研项目部分样式 */
  .research-section {
    padding: 2.5rem;
    background-color: rgba(255, 255, 255, 0.9);
    width: 75%;
    max-width: 75rem;
    margin: 0 auto;
  }
  
  .research-section h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .research-subsection {
    margin-bottom: 2rem;
  }
  
  .research-subsection h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .research-subsection p {
    font-size: 1.125rem;
    line-height: 1.6;
    color: #555;
  }
  
  /* 学科竞赛部分样式 */
  .competitions-section {
    padding: 2.5rem;
    background-color: rgba(255, 255, 255, 0.9);
    width: 75%;
    max-width: 75rem;
    margin:2rem auto;
  }
  
  .competitions-section h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .competitions-section p {
    font-size: 1.125rem;
    line-height: 1.6;
    color: #555;
  }
  
  /* 分割线样式 */
  .section-divider {
    width: 80%;
    height: 2px;
    background: linear-gradient(to right, transparent, #aaa, transparent);
    margin: 4rem 0 4rem 10%; /* 增加上下间距 */
  }
  
  /* 响应式布局 */
  @media (max-width: 768px) {
    .navbar .nav-container {
      justify-content: space-between;
    }
  
    .navbar .mobile-menu-icon {
      display: block;
    }
  
    .navbar .nav-menu {
      display: none;
      flex-direction: column;
      background-color: rgba(255, 255, 255, 0.95);
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding: 1rem 0;
    }
  
    .navbar .nav-menu.active {
      display: flex;
    }
  
    .navbar .nav-menu li {
      margin: 0;
    }
  
    .navbar .nav-menu li a {
      padding: 1rem;
      text-align: center;
      display: block;
    }
  
    .intro-container {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  
    .carousel {
      max-width: 100%;
      width: 100%;
      min-width: unset;
      margin-bottom: 1rem;
    }
  
    .carousel-img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 0.5rem;
    }
  
    .info-panel {
      padding-left: 0;
      text-align: left;
      max-width: 95%;
      width: 95%;
      min-width: unset;
    }
  
    .bio {
      width: 95%;
      text-align: left;
    }
  
    .education-section,
    .research-section,
    .competitions-section {
      width: 95%;
    }
  }
  